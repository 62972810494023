body {
	margin: 0;
	padding: 0;
	font: 2.6vh/1.2 'Roboto', sans-serif;
	font-weight: 300;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
input, textarea {
	font-family: 'Roboto', sans-serif;
	font-size: 1em;
	font-weight: 300;
}

.btn {
	background-image: linear-gradient(to right, #b5004f, #e90089);
	border:none;
	color:#fff;
	padding:1vh;
	margin:0;
	width: 100%;
	font-family: 'Roboto', sans-serif;
	font-size: 1em;
	font-weight: 300;
	cursor: pointer;
	outline: none;
	text-transform: uppercase;
}

.App {
	min-height: 100vh;
	background-image: linear-gradient(to bottom, #7c9cab, #24363f);
}

.StartScreen {
	margin: 0 auto;
}
.StartScreen .video {
	background: #000;
	height: 100vh;
	width: 100%;
	position: relative;
	overflow: hidden;
}
.StartScreen .video video {
	position: absolute;
	top:50%;
	left:50%;
	transform: translateX(-50%) translateY(-50%);
	max-height: 100%;
}
.StartScreen .content {
	position: absolute;
	bottom: 11vh;
	left: 0;
	right: 0;
	color: #fff;
	text-shadow: 2px 1px 4px rgba(0,0,0,0.6);
}
.StartScreen .content .logo {
	display: block;
	margin: 0 auto 2vh;
	width: 10vh;
}
.StartScreen .content .title {
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
}
.StartScreen .content .desc {
	display: block;
	width:50vh;
	max-width: 100%;
	margin: 1vh auto;
	text-align: center;
	text-transform: uppercase;
}
.StartScreen .continue {
	position:absolute;
	bottom:0;
	font-size: 1.2em;
	padding: 2.2vh;
}

.Scanner {
	width: 100vh;
	max-width: 100%;
	margin:0 auto;
	padding: 4vh 0 0;
}
.Scanner .logo {
	display: block;
	margin: 0 auto 4vh;
	width: 10vh;
}
.Scanner .react-swipeable-view-container {
	height:77vh;
}
.Scanner .container {
	background: #fff;
	height:65vh;
	margin:1vh 4vh;
	border-radius: 10px 10px 0 0;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	padding:5.5vh;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
}
.Scanner .container:after {
	content: "";
	display: block;
	position: absolute;
	top: 50vh;
	left:0;
	right:0;
	height:2vh;
	width: 101%;
	background: #000;
}
.Scanner .container:before {
	content: "";
	display: block;
	position: absolute;
	bottom:0;
	left:0;
	right:0;
	height:0.5vh;
	width:101%;
	background:linear-gradient(to left, #e43b72, #ff8acf);
}
.Scanner .page-1 .desc {
	display: block;
	padding: 2vh 0;
	max-width: 39vh;
	margin: 0 auto;
	text-align: center;
	color:#405864;
	font-size:0.8em;
	text-transform: uppercase;
}
.Scanner .page-1 .example {
	display: block;
	max-width:100%;
	height:12vh;
	margin: 5vh auto;
}
.Scanner .page-1 .scan {
	font-size:1.2em;
	position: absolute;
	bottom: 3.5vh;
	width: calc(100% - 11vh);
}

.Scanner .page-2 {
	padding:0 0 2.2vh;
}

.Scanner .page-2 .videoContainer {
	width:100%;
	height:50vh;
	background: #c4c4c4;
	position: relative;
}
.Scanner .page-2 .videoContainer .correctionSize {
	width:100%;
	height:100%;
}
.Scanner .page-2 .videoContainer .videoStream {
	width:100%;
	height:100%;
}
.Scanner .page-2 .videoContainer .videoStreamOverlay {
	position: absolute;
	left:50%;
	top:50%;
	width: 100%;
	height: 100%;
	max-height: 58.4vw;
	max-width: 80vh;
	transform: translateX(-50%) translateY(-50%);
}
.Scanner .page-2 .repeat {
	font-size:1.2em;
	position: absolute;
	bottom: 3.5vh;
	left: 5.5vh;
	width: calc(100% - 11vh);
}

.Scanner .page-3 .desc {
	display: block;
	padding: 2vh 0;
	max-width: 32vh;
	margin: 0 auto;
	text-align: center;
	color:#405864;
	font-size:0.8em;
	text-transform: uppercase;
}
.Scanner .page-3 .codeInput {
	border: none;
	border-bottom: 2px solid #444;
	width: calc(100% + 11vh);
	display:block;
	margin: 11vh -5.5vh;
	outline:none;
	color:#e43b72;
	font-size: 5vh;
	letter-spacing: 1vh;
	box-sizing: border-box;
	padding: 0 6vh;
	text-align: center;
}

.Scanner .bottom {
	position: relative;
	bottom: 4vh;
	left: 0;
	max-width: 90%;
	right: 0;
	margin: 0 auto;
}
.Scanner .bottom .lastPage {
	color:#fff;
	cursor: pointer;
	font-weight: 400;
	position: absolute;
	right: 0;
	top:0;
	padding:0 3vh 0 0;
}
.Scanner .bottom .lastPage:after {
	content: "";
	position: absolute;
	right:0;
	width: 1.7vh;
	height: 3.1vh;
	background-image: url("./img/Vector-right.png");
	background-size: cover;
}
.Scanner .bottom .circles {
	position: absolute;
	left:0;
	top:0;
	font-size:0;
}
.Scanner .bottom .circles i {
	display: inline-block;
	width:1.5vh;
	height: 1.5vh;
	margin: 1vh;
	border-radius: 50%;
	border: 1px solid #fff;
	cursor: pointer;
}
.Scanner .bottom .circles i:hover, .Scanner .bottom .circles i.active {
	background: #fff;
}


.MainScreen {
	background: #fff;
	position: relative;
}
.MainScreen .topBar {
	position: relative;
	padding: 4vh;
}
.MainScreen .topBar img {
	display: block;
	margin:0 auto;
	height:10vh;
	max-width: 80%;
}

.MainScreen .categories {
	min-height: 80vh;
	margin: 1vh 0 0;
}
.MainScreen .categories .category {
	margin: 2vh 0 0;
}
.MainScreen .categories .category h2 {
	text-align: center;
	margin: 0 2vh 2vh;
	font-weight: 400;
	font-size: 1em;
}
.MainScreen .categories .slider {
	background: #000;
}
.MainScreen .categories .category .application {
	position: relative;
	max-width: 100%;
	overflow: hidden;
	height: 50vh;
}
.MainScreen .categories .category .application img {
	display: block;
	max-height: 50vh;
	max-width: 100%;
	position: absolute;
	left:50%;
	transform: translateX(-50%);
}
.MainScreen .categories .category .application-2x {
	display: inline-block;
	position: relative;
	width: 50%;
	overflow: hidden;
	height: 50vh;
}
.MainScreen .categories .category .application-2x img {
	display: block;
	max-height: 25vh;
	max-width: 60%;
	position: absolute;
	left:50%;
	top:50%;
	transform: translateX(-50%) translateY(-75%);
}
.MainScreen .categories .category .application-2x .name {
	position: absolute;
	left: 50%;
	top: 67%;
	-webkit-transform: translateX(-50%) translateY(-60%);
	transform: translateX(-50%) translateY(-50%);
	color: #fff;
	text-align: center;
}
.MainScreen .categories .category .application .moreInfo, .MainScreen .categories .category .application-2x .moreInfo {
	width: 4vh;
	height: 4vh;
	position: absolute;
	right: 1.5vh;
	top: 1vh;
	background: url("img/info.png") no-repeat;
	background-size: contain;
	cursor: pointer;
}
.MainScreen .categories .category .controls {
	position: relative;
	font-size:0;
	margin:-10vh 0 0;
}
.MainScreen .categories .category .controls .links {
	background: #fff;
	padding: 4px 0 0;
	clip-path: polygon(0 32%, 100% 0%, 100% 100%, 0 100%);
}
.MainScreen .categories .category .controls .links .link-path {
	clip-path: polygon(0 35%, 100% 0%, 100% 100%, 0 100%);
}
.MainScreen .categories .category .controls .links a.disabled {
	pointer-events: none;
	background: #c3bfbf;
}
.MainScreen .categories .category .controls .links .download {
	display: inline-block;
	vertical-align: top;
	background: #d5d1d1;
	width:25%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	border-right:2px solid #ECE8E8;
	cursor: pointer;
}
.MainScreen .categories .category .controls .links .download:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(img/download.png) no-repeat;
	background-size: contain;
	height: 6vh;
	width: 6vh;
	top: 65%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}
.MainScreen .categories .category.cat-2x .controls .links .download.second:after {
	top: 56%;
	height: 6.5vh;
	width: 6.5vh;
}
.MainScreen .categories .category.cat-2x .controls .links .googlePlay.second:after {
	top: 53%;
	height: 7vh;
	width: 7vh;
}
.MainScreen .categories .category .controls .links .googlePlay {
	display: inline-block;
	vertical-align: top;
	background: #d5d1d1;
	width:25%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	border-right:2px solid #ECE8E8;
	cursor: pointer;
}
.MainScreen .categories .category .controls .links .googlePlay:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(img/googleplay.png) no-repeat;
	background-size: contain;
	height: 6.5vh;
	width: 6.5vh;
	top: 60%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}
.MainScreen .categories .category .controls .links .paid {
	display: inline-block;
	vertical-align: top;
	background: #3f5661;
	width:50%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	cursor: pointer;
}
.MainScreen .categories .category .controls .links .paid:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(img/key.png) no-repeat;
	background-size: contain;
	height: 9vh;
	width: 9vh;
	top:-5%;
	left: 50%;
	transform: translateX(-50%);
}
.MainScreen .categories .category .controls .links .paid.lower:after {
	top: 5%;
	height: 12vh;
	width: 12vh;
}
.MainScreen .categories .category .controls .links .paid span {
	font-size: 2.8vh;
	position: absolute;
	top: 7vh;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
	color:#fff;
}
.MainScreen .categories .category .controls .steps {
	width: 100%;
	text-align: center;
}
.MainScreen .categories .category .controls .steps {
	width: 100%;
	text-align: center;
	padding:1vh 0 1.5vh;
	border-bottom:1px solid #3f5661;
}
.MainScreen .categories .category .controls .steps i {
	display: inline-block;
	width: 1.3vh;
	height: 1.3vh;
	margin: 0.7vh;
	border-radius: 50%;
	border: 1px solid #3f5661;
	cursor: pointer;
}
.MainScreen .categories .category .controls .steps i:hover, .MainScreen .categories .category .controls .steps i.active {
	background: #3f5661;
}

.MainScreen .popup {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	background: #f8104d;
}
.MainScreen .popup .close {
	display: block;
	position: absolute;
	width: 24px;
	height: 24px;
	top:3vh;
	right:3vh;
	background: url("img/close.png");
	background-size: cover;
	cursor: pointer;
}
.MainScreen .popup .desc {
	display: block;
	margin: 28vh auto 0;
	font-size: 1.6em;
	text-align: center;
	max-width: 45vh;
	color: #fff;
}
.MainScreen .popup .controls {
	max-width: 45vh;
	text-align: center;
	margin: 0 auto;
}
.MainScreen .popup .controls .buttonPopup {
	display: inline-block;
	color:#fff;
	font-size: 1.2em;
	margin: 15vh 0 0 6vh;
	padding: 1.6vh 6.6vh;
	border:2px solid #fff;
	cursor: pointer;
}
.MainScreen .popup .controls .buttonPopup:first-child {
	margin: 15vh 0 0;
}
.MainScreen .popup .keyArea {
	width: 100%;
	box-sizing: border-box;
	background: #cc0d3f;
	border-top:1px solid #fff;
	border-bottom: 1px solid #fff;
	padding: 5vh 2vh;
	text-align: center;
	margin-top: 5vh;
	font-size: 1.8em;
	color:#fff;
}
.MainScreen .popup .copyToClipboard {
	display: block;
	text-align: right;
	margin:5vh 10vh;
	font-size:1.1em;
	color:#fff;
	text-transform: uppercase;
	position: relative;
}
.MainScreen .popup .copyToClipboard:after {
	content: "";
	position: absolute;
	top:-0.5vh;
	right:-6vh;
	width: 4vh;
	height: 4vh;
	background: url("img/copy.png");
	background-size: cover;
}
.MainScreen .popup .copied {
	position: absolute;
	bottom: 2vh;
	display: block;
	text-align: center;
	border: 1px solid #fff;
	padding: 2vh 4vh;
	left:50%;
	transform: translateX(-50%);
	color:#fff;
	word-wrap: break-word;
	white-space: nowrap;
}
.MainScreen .topBar .navigation {
	position: absolute;
	right: 30px;
	top: 6.2vh;
	background: url(img/dots.png);
	height:5vh;
	width: 1vh;
	background-size: contain;
	cursor: pointer;
}
.MainScreen .infoPopup {
	position: absolute;
	top:0;
	left:0;
	width: 100%;
	min-height: 100vh;
	background: #000;
	color:#fff;
	overflow: hidden;
}
.MainScreen .titleCat {
	display: block;
	padding: 3.5vh 0 2vh 0;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
}
.MainScreen .infoPopup .close {
	display: block;
	position: absolute;
	width: 24px;
	height: 24px;
	top:3vh;
	right:3vh;
	background: url("img/close.png");
	background-size: cover;
	cursor: pointer;
	z-index: 1;
}
.MainScreen .infoPopup img {
	display: block;
	max-height: 50vh;
	max-width: 100%;
	position: relative;
	left:50%;
	transform: translateX(-50%);
}
.MainScreen .infoPopup .content {
	margin-top: -4vh;
	padding: 0 4vh 4vh;
	text-align: justify;
	position: relative;
	background: #000;
}
.MainScreen .infoPopup .content:after {
	content: "";
	position: absolute;
	top: -10vh;
	left: 0;
	width: 100vw;
	height: 10vh;
	background: linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,1));
	clip-path: polygon(0 35%, 100% 0%, 100% 100%, 0 100%);
}
.MainScreen .infoPopup .controls {
	position: relative;
	font-size:0;
}
.MainScreen .infoPopup .controls .link-path {
	clip-path: polygon(0 35%, 100% 0%, 100% 100%, 0 100%);
}
.MainScreen .infoPopup .controls .links, .MainScreen .infoPopup .controls .links-2x {
	background: #424242;
	padding: 4px 0 0;
	clip-path: polygon(0 32%, 100% 0%, 100% 100%, 0 100%);
}
.MainScreen .infoPopup .download {
	display: inline-block;
	vertical-align: top;
	background: #000;
	width:25%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	border-right:2px solid #424242;
	cursor: pointer;
}
.MainScreen .infoPopup .download:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(img/download-white.png) no-repeat;
	background-size: contain;
	height: 6vh;
	width: 6vh;
	top:65%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}
.MainScreen .infoPopup .googlePlay {
	display: inline-block;
	vertical-align: top;
	background: #000;
	width:25%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	border-right:2px solid #424242;
	cursor: pointer;
}
.MainScreen .infoPopup .googlePlay:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(img/googleplay-white.png) no-repeat;
	background-size: contain;
	height: 6.5vh;
	width: 6.5vh;
	top:60%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}
.MainScreen .infoPopup .controls .links-2x .download, .MainScreen .infoPopup .controls .links-2x .googlePlay {
	width: 50%;
}
.MainScreen .infoPopup .paid {
	display: inline-block;
	vertical-align: top;
	background: #000;
	width:50%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	cursor: pointer;
}
.MainScreen .infoPopup .paid:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(img/key.png) no-repeat;
	background-size: contain;
	height: 9vh;
	width: 9vh;
	top:-5%;
	left: 50%;
	transform: translateX(-50%);
}
.MainScreen .infoPopup .paid.lower:after {
	top: 5%;
	height: 12vh;
	width: 12vh;
}
.MainScreen .infoPopup .paid span {
	font-size: 2.8vh;
	position: absolute;
	top: 7vh;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
	color:#fff;
}

.LeftMenu {
	min-height: 100vh;
	background: #2d414b;
	box-sizing: border-box;
	padding: 3.3vh 0 0;
	position: relative;
	overflow: hidden;
}
.LeftMenu .title {
	margin:0 auto;
	font-size: 1em;
	font-weight: 300;
	text-align: center;
	text-transform: uppercase;
	color:#fff;
}
.LeftMenu .close {
	display: block;
	position: absolute;
	width: 24px;
	height: 24px;
	top:3vh;
	right:3vh;
	background: url("img/close.png");
	background-size: cover;
	cursor: pointer;
}
.LeftMenu .menu {
	list-style: none;
	margin: 12vh 0 0;
	padding: 0;
}
.LeftMenu .menu li {
	display: block;
	list-style: none;
	margin: 4vh 2vh 4vh 9vh;
	color:#fff;
	position: relative;
	font-size: 0.8em;
}
.LeftMenu .menu li.language:after {
	content: " ";
	position: absolute;
	left: -6vh;
	top: -0.9vh;
	width: 4vh;
	height: 4vh;
	background: url(img/worlwide.png) no-repeat top right;
	background-size: cover;
}
.LeftMenu .menu li.language .right {
	position: absolute;
	right: 0;
}
.LeftMenu .menu li.feedback:after {
	content: " ";
	position: absolute;
	left: -6vh;
	top: -0.8vh;
	width: 4.4vh;
	height: 4vh;
	background: url(img/icon.png) no-repeat top right;
	background-size: contain;
}
.LeftMenu .menu li.feedback .hidden {
	margin:4vh 0 0 -7vh;
	text-align: right;
}
.LeftMenu .menu li.feedback .hidden input, .LeftMenu .menu li.feedback .hidden textarea {
	display: block;
	width: 100%;
	margin: 3vh 0;
	padding: 1vh 2vh;
	border: none;
	box-sizing: border-box;
	background: none;
	color:#fff;
	outline: none;
}
.LeftMenu .menu li.feedback .hidden input {
	border-bottom:1px solid #fff;
}
.LeftMenu .menu li.feedback .hidden input::placeholder, .LeftMenu .menu li.feedback .hidden textarea::placeholder {
	color:#fff;
	opacity: 0.8;
}
.LeftMenu .menu li.feedback .hidden .submit {
	display: inline-block;
	margin:3vh 0 0;
	width: 35%;
	max-width: 20vh;
	text-transform: uppercase;
	border:2px solid #fff;
	font-weight: bold;
}
.LeftMenu .menu li.feedback .hidden textarea {
	border: 1px solid #fff;
	margin: 5vh 0 0;
}
.LeftMenu .menu li.logout:after {
	content: " ";
	position: absolute;
	left: -7vh;
	top: -0.6vh;
	width: 5vh;
	height: 4vh;
	background: url(img/Exit.png) no-repeat top right;
	background-size: contain;
}

.loader {
	position: fixed;
	top:0;
	left:0;
	background: rgba(255,255,255,0.3);
	width: 100vw;
	height: 100vh;
	z-index: 10;
}

