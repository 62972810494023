body {
	margin: 0;
	padding: 0;
	font: 2.6vh/1.2 'Roboto', sans-serif;
	font-weight: 300;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
input, textarea {
	font-family: 'Roboto', sans-serif;
	font-size: 1em;
	font-weight: 300;
}

.btn {
	background-image: -webkit-linear-gradient(left, #b5004f, #e90089);
	background-image: linear-gradient(to right, #b5004f, #e90089);
	border:none;
	color:#fff;
	padding:1vh;
	margin:0;
	width: 100%;
	font-family: 'Roboto', sans-serif;
	font-size: 1em;
	font-weight: 300;
	cursor: pointer;
	outline: none;
	text-transform: uppercase;
}

.App {
	min-height: 100vh;
	background-image: -webkit-linear-gradient(top, #7c9cab, #24363f);
	background-image: linear-gradient(to bottom, #7c9cab, #24363f);
}

.StartScreen {
	margin: 0 auto;
}
.StartScreen .video {
	background: #000;
	height: 100vh;
	width: 100%;
	position: relative;
	overflow: hidden;
}
.StartScreen .video video {
	position: absolute;
	top:50%;
	left:50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
	max-height: 100%;
}
.StartScreen .content {
	position: absolute;
	bottom: 11vh;
	left: 0;
	right: 0;
	color: #fff;
	text-shadow: 2px 1px 4px rgba(0,0,0,0.6);
}
.StartScreen .content .logo {
	display: block;
	margin: 0 auto 2vh;
	width: 10vh;
}
.StartScreen .content .title {
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
}
.StartScreen .content .desc {
	display: block;
	width:50vh;
	max-width: 100%;
	margin: 1vh auto;
	text-align: center;
	text-transform: uppercase;
}
.StartScreen .continue {
	position:absolute;
	bottom:0;
	font-size: 1.2em;
	padding: 2.2vh;
}

.Scanner {
	width: 100vh;
	max-width: 100%;
	margin:0 auto;
	padding: 4vh 0 0;
}
.Scanner .logo {
	display: block;
	margin: 0 auto 4vh;
	width: 10vh;
}
.Scanner .react-swipeable-view-container {
	height:77vh;
}
.Scanner .container {
	background: #fff;
	height:65vh;
	margin:1vh 4vh;
	border-radius: 10px 10px 0 0;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	padding:5.5vh;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
}
.Scanner .container:after {
	content: "";
	display: block;
	position: absolute;
	top: 50vh;
	left:0;
	right:0;
	height:2vh;
	width: 101%;
	background: #000;
}
.Scanner .container:before {
	content: "";
	display: block;
	position: absolute;
	bottom:0;
	left:0;
	right:0;
	height:0.5vh;
	width:101%;
	background:-webkit-linear-gradient(right, #e43b72, #ff8acf);
	background:linear-gradient(to left, #e43b72, #ff8acf);
}
.Scanner .page-1 .desc {
	display: block;
	padding: 2vh 0;
	max-width: 39vh;
	margin: 0 auto;
	text-align: center;
	color:#405864;
	font-size:0.8em;
	text-transform: uppercase;
}
.Scanner .page-1 .example {
	display: block;
	max-width:100%;
	height:12vh;
	margin: 5vh auto;
}
.Scanner .page-1 .scan {
	font-size:1.2em;
	position: absolute;
	bottom: 3.5vh;
	width: calc(100% - 11vh);
}

.Scanner .page-2 {
	padding:0 0 2.2vh;
}

.Scanner .page-2 .videoContainer {
	width:100%;
	height:50vh;
	background: #c4c4c4;
	position: relative;
}
.Scanner .page-2 .videoContainer .correctionSize {
	width:100%;
	height:100%;
}
.Scanner .page-2 .videoContainer .videoStream {
	width:100%;
	height:100%;
}
.Scanner .page-2 .videoContainer .videoStreamOverlay {
	position: absolute;
	left:50%;
	top:50%;
	width: 100%;
	height: 100%;
	max-height: 58.4vw;
	max-width: 80vh;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
}
.Scanner .page-2 .repeat {
	font-size:1.2em;
	position: absolute;
	bottom: 3.5vh;
	left: 5.5vh;
	width: calc(100% - 11vh);
}

.Scanner .page-3 .desc {
	display: block;
	padding: 2vh 0;
	max-width: 32vh;
	margin: 0 auto;
	text-align: center;
	color:#405864;
	font-size:0.8em;
	text-transform: uppercase;
}
.Scanner .page-3 .codeInput {
	border: none;
	border-bottom: 2px solid #444;
	width: calc(100% + 11vh);
	display:block;
	margin: 11vh -5.5vh;
	outline:none;
	color:#e43b72;
	font-size: 5vh;
	letter-spacing: 1vh;
	box-sizing: border-box;
	padding: 0 6vh;
	text-align: center;
}

.Scanner .bottom {
	position: relative;
	bottom: 4vh;
	left: 0;
	max-width: 90%;
	right: 0;
	margin: 0 auto;
}
.Scanner .bottom .lastPage {
	color:#fff;
	cursor: pointer;
	font-weight: 400;
	position: absolute;
	right: 0;
	top:0;
	padding:0 3vh 0 0;
}
.Scanner .bottom .lastPage:after {
	content: "";
	position: absolute;
	right:0;
	width: 1.7vh;
	height: 3.1vh;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAqCAYAAACpxZteAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABQSURBVHgB7ZfBCcAwEMNMJ/P+S7mfQqFNfneQBGkACfyzkjgvVjX5Y1WSMVYVn4mIECFChAiRPSOXVqd1IuTIkSNHfob8CfTJJwGrkjQf8RstHQ3B+Ej+dAAAAABJRU5ErkJggg==);
	background-size: cover;
}
.Scanner .bottom .circles {
	position: absolute;
	left:0;
	top:0;
	font-size:0;
}
.Scanner .bottom .circles i {
	display: inline-block;
	width:1.5vh;
	height: 1.5vh;
	margin: 1vh;
	border-radius: 50%;
	border: 1px solid #fff;
	cursor: pointer;
}
.Scanner .bottom .circles i:hover, .Scanner .bottom .circles i.active {
	background: #fff;
}


.MainScreen {
	background: #fff;
	position: relative;
}
.MainScreen .topBar {
	position: relative;
	padding: 4vh;
}
.MainScreen .topBar img {
	display: block;
	margin:0 auto;
	height:10vh;
	max-width: 80%;
}

.MainScreen .categories {
	min-height: 80vh;
	margin: 1vh 0 0;
}
.MainScreen .categories .category {
	margin: 2vh 0 0;
}
.MainScreen .categories .category h2 {
	text-align: center;
	margin: 0 2vh 2vh;
	font-weight: 400;
	font-size: 1em;
}
.MainScreen .categories .slider {
	background: #000;
}
.MainScreen .categories .category .application {
	position: relative;
	max-width: 100%;
	overflow: hidden;
	height: 50vh;
}
.MainScreen .categories .category .application img {
	display: block;
	max-height: 50vh;
	max-width: 100%;
	position: absolute;
	left:50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}
.MainScreen .categories .category .application-2x {
	display: inline-block;
	position: relative;
	width: 50%;
	overflow: hidden;
	height: 50vh;
}
.MainScreen .categories .category .application-2x img {
	display: block;
	max-height: 25vh;
	max-width: 60%;
	position: absolute;
	left:50%;
	top:50%;
	-webkit-transform: translateX(-50%) translateY(-75%);
	        transform: translateX(-50%) translateY(-75%);
}
.MainScreen .categories .category .application-2x .name {
	position: absolute;
	left: 50%;
	top: 67%;
	-webkit-transform: translateX(-50%) translateY(-60%);
	transform: translateX(-50%) translateY(-50%);
	color: #fff;
	text-align: center;
}
.MainScreen .categories .category .application .moreInfo, .MainScreen .categories .category .application-2x .moreInfo {
	width: 4vh;
	height: 4vh;
	position: absolute;
	right: 1.5vh;
	top: 1vh;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMTSURBVHgB7ZtNdpswEIAFr3dJX7rxDaycwJt0nyNk1S5tL9tVjpB9u8kJLN8gm+TFp6EMMFQMgwAz2BLK9x7E/LxYn2eQhBCJmpEsy3T+Z11t7npOx+PHJEmMCoVccpsvh2w6B/hfykcgkpmMpEteKwESNYGqEBAF3Xfu6/uHen07scdW327U6varGoDJl/2UlD9bGH515RB9/vNSSr6f1BhWt6X8w/eN6zSTS9+pMxgtXEX1wB0DwVJ0nGQXIA/ijujfjY32KOGuqEqLUnrER0V7sDAnO7coxSE+WHqQMCcLos9/X9Q1eLjfcNf4IOleYU72cf/rYlHtAqL9tP1Jd/dKO4V9lUXOke4U9l0WGSudcjurpkfb+3yUBaBMUDaC7uqZsRGGvpy9LSmLNS0g3WbTSOdRbvm1Ilylcs0chYJmBZby842SAMoIZbWhLkBKTtDKSuWinRVseriOw8A+9CCgrFBmi1Zq0wg3bsfoLxYCTJkbTrUwF13pSor8+p37pn3HyRnl+qKmzdBctTLeDZWF+5jtO0gFVjdTtnBdM0NBHve/Vcg8bX806gessYuUpkMpIV67FKbGLhzxGtb2QR87GGNhHDSsWsJLiC5CXDSsUtpOSdea14S6gCtEeN08Kfx0RhiXdaoiA4R3uHGpdC5GLO436hIQp92XxsG3+dOZDs/MPUwETnZ7fPGUtmV7xp5nIcprOCo+hZdOQxie4i0N6tRohyWHW3yBOO2iTOmjvUNqFNEHGJdjSp+vLimtqQu4Ykob3HmN3s9cEBcDq5YwsIS0ZhwMrArhPNR7+8gSokwd0NGupQ1+KB+FhBtleyi4wuAHW3gxUWbKXrvVwlVtbXA71Chz0bVbItrxCD7KrugCDWEuytJDMfbQqfSQMJTVFV2g1bWkUwXKaUJyqV0+T/q/SGE/aEe4aQ9dMwC0IrPtfJ3yAHTM82Bn6bE3DzS1Acmn9ZI4JrUY7vzPaUuUqCamIVFNPUSimlyKRDV9GIlqgrhNNK8A2IT4kocIWUCv8YiTef6i1qSU7iPz8FW8f26jR0Pn1zmMAAAAAElFTkSuQmCC) no-repeat;
	background-size: contain;
	cursor: pointer;
}
.MainScreen .categories .category .controls {
	position: relative;
	font-size:0;
	margin:-10vh 0 0;
}
.MainScreen .categories .category .controls .links {
	background: #fff;
	padding: 4px 0 0;
	-webkit-clip-path: polygon(0 32%, 100% 0%, 100% 100%, 0 100%);
	        clip-path: polygon(0 32%, 100% 0%, 100% 100%, 0 100%);
}
.MainScreen .categories .category .controls .links .link-path {
	-webkit-clip-path: polygon(0 35%, 100% 0%, 100% 100%, 0 100%);
	        clip-path: polygon(0 35%, 100% 0%, 100% 100%, 0 100%);
}
.MainScreen .categories .category .controls .links a.disabled {
	pointer-events: none;
	background: #c3bfbf;
}
.MainScreen .categories .category .controls .links .download {
	display: inline-block;
	vertical-align: top;
	background: #d5d1d1;
	width:25%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	border-right:2px solid #ECE8E8;
	cursor: pointer;
}
.MainScreen .categories .category .controls .links .download:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASLSURBVHgB7VxLUtwwEG0Z7hFYptiQE2DukLCGkwAngTXkDnhuwCZZcxIUtbCIbFofW1LL4/Grohhsj2U/tfovBFRCe3V9i7+lFC0IaMmLJHRCyA7eYdf9fuygAgQwov153Uohbp2EhICESXnPSRYLQZqYRrxALjASVZSgZIkJQRG1e364hIIoRlB2qfFAvMvLUtJUhCBUwBLEnfeiXgF3T4/3wXv5FHmPUiRlJ+ji182L72UEyLsQKS6EiC9BUlaCvC+QUbH6JiE3SdkI8umcFKmZNV5GkhrIBO/DZiYHgQTgvalz2nJmwhFkQC/yJ+PjJa0L4u3v69vp9/MdCHE9HBhOTs/O4e3P6w4SkSxBKOqkPlA6h8ORc0lS0IpGIpkgUpwZHDgbeiLUmOPjJt5LQboOIqQHrRUwg5qQHFKURBA5Q0xLiwJay/GxVClKIoiaoRrSY0BZS+2FJyCbmdeoKD32Mwz+TgyUZxNEia5OblUG9Qza0s7E8ZSL9UANXODSktQFKvMHlYHL7OLq5m5wUD0znoIZiCLI5HVkQFyrL68CCC4x9JJ1GFEq6cWAFEXtlKDJCS85T4RLAM39wMImTC4pQbHk4IPoH+Xqc3rOczBXUR9TN/KSU6GykAP4Tkp5689T0i+DfJCXnD0iRhEhoy6MiBkHEuTMozAHn8lAfRijd9Q1SKYvLfOpg7Tj50hb7BU5MD3c0csPc1rUvcwHUiz3kBwKttfvjfCJ9xXmBtQXd08PrKVpLviKC2MFrpcYGZUTqYO1AAnQk0/4bsiF7RI0Lv+gRKJ9adDLiSLJMlZNH8gNsGbpGYMkSRkro7foWGwBUTknKKtn1M7Rt7Mf3fjk7vnxBg4IunykykSKqtY+jiWlvBnFPQapc5X62QjyANMkG0EWvhgnpaw3ggLYCAqgIYttCVWAtYFOuSZUAebCjo9Kd4VMQUNWIzN1RsRCJ+qsMbmaP8f48t7Kw27Mh/HFOTojokGEO6zjA61WsAipCfK52gcDYpIw5NIEufprXFm2tWG8xDX6PoNPM0+mKTFnewAkUbl4U+P/JMglRWsniWwpRunpjdfAUXQlkAxJa/OPXP3W9mpqfCeHJ6A12f99JwotpC5SUORgTtrywb44inhSEXDp9EWQKCFaXaU0G95ikWlj3FwXwOz5cFYVcWk9D/1C0pPuX0KE9l18DDahc6LRLzd/r0Zf+Y0rmxLw1WgcJS5vsIpfyJ2fTvGvcnbQ2/A1XwSjeVMiWWUiH1VEIO6LbsHrl8V97P4tF1z7K6K+qwwI6j9IhJ7sSH04qUcRYYiyj8UqzdRamzEgZFhQaPzJBOUaePZYH7PeARO2jGIAG0EBbAQFkEUHxcBW5CmbelPvMRUsBOlea/jvFqi/26mNWTnuMQc8S2zsM01MoZAhD1Njez0dFEmSMx5kalxnIcjpPQdI8u6PZ9qXxtaDmO1/eTD3a7M2aSaTVKHrlr2LdTZJlVqSq7X5BpNxNir2a1ftg44iqXIze/VGcS9JC+j0X0Qnvas2tYRtEIsIVsf1OMz4LWWPyD83PqgzlKvcEAAAAABJRU5ErkJggg==) no-repeat;
	background-size: contain;
	height: 6vh;
	width: 6vh;
	top: 65%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
}
.MainScreen .categories .category.cat-2x .controls .links .download.second:after {
	top: 56%;
	height: 6.5vh;
	width: 6.5vh;
}
.MainScreen .categories .category.cat-2x .controls .links .googlePlay.second:after {
	top: 53%;
	height: 7vh;
	width: 7vh;
}
.MainScreen .categories .category .controls .links .googlePlay {
	display: inline-block;
	vertical-align: top;
	background: #d5d1d1;
	width:25%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	border-right:2px solid #ECE8E8;
	cursor: pointer;
}
.MainScreen .categories .category .controls .links .googlePlay:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPfSURBVHgB7ZxbdtsgEIbBp/uKs4c2z8lKkqwkeU66hzgbK9VPjY8ko2EGBoRUf2+xLkTfgRHDRdYQHH8+Hs3B3DljXyYHnDlZ606nj/dXs3Ps0oG7X09fw9GjSd7AvexZ1JUg1Bp3sF9GAmqUc6+n3+8nszOuBN09PDmTyw5FTQSRzeocd5yzx2TTG879/ny7NzvgMPmLevDzMTz498ebhQTqXNTE48Pjs9k4lxqEh7l6W8UY1Q4fr6x9TtWoLQfyA3k0VktQO9AUBxBrIMv+cfdUjYJ4XOO7DRuDFORrSkISuIgaasrizYZr8HbcmqhD6gSuJIBmhPi0J1FJQUAiCUhE9R7IWYJASlKsNkAUKz51/MZjCwKUJF8bYpIkgRyiOmt2IkGACsZLksBEFEFv8UksCFyaTgRKkr8WojYUyLMEATxoriR//UYCebYgUCrJ36PzQF4kCCQlMR5KHMgbiioWBEhJ5zTDMOgxdVERBC6SBB1K6l69pC5qgkB4MA1J/n7C1MVUQFVQQFMSCKLWGIOqIghoSwr3bB3IqwkCOflbitaBvKogkJO/sUmNjSsE8uqCAJWD5UgST00ViGoiCGj0usGiHIyVV0hdmgkCpZJIOeeJBO3UpakgkCuJI2dchtYYVHNBQJq/SeTMy5GMQcWOrSIIcPO3XDnzsjjxKSZpNUEg/ONUX6lUzqS8VOoylDmvvasKClB9pavfFOb9qUA+n13uQhBYlDRGcVEElViPa1E3gkBKUo0VI6l7diXIv26J9GGNQfxuBLHSB438TUgXgqhXeez8lpJWF5Tq52gmuTFSY1OrCmLlVkpJbqzspSWH48VeqwmS5laqwyVYTYeyI3LmnchVBOWkDxrzbzgHyeniUsOh/PlSweaCSnKr3Pk335woMUT5TQVpJp6cCYEQZ1LdB0hfKv+HaYSGnDG4JhpkR5KcwurbJoK05QQoSdR1kmXJ1ZtYLTkBVpI7KhPNSbJmu2oNqi3Hl+EXwCemfwr2kFQTVFtOWOVPyikQE6giqKacixhOnPks3/6gLqimHP/KbrwvRFVQLTmsjTaKcW2MmqAacoIYR52kEGcoVARpy2kVgDkUC9KU0zoAcygSpCVHJKbxxrxsQWpyGAF4zR2LWYI05PQQgDmIBZXKCdf3LiYgElQihx1nkEx2tO+eLShXTs8BmANLULaczgMwh6SgHDlbCcAcSEFSOXsSEyAFceX0lBpoI3vNz+T0mBpoY8d/kJ/GmcnhfICp9wDMgf15nDAl+799HsfOf7h9YGnKVQxCT1a0DwLsUEzALh24feTtH5Y6ePtMoDF/ASkYi4nkzKNgAAAAAElFTkSuQmCC) no-repeat;
	background-size: contain;
	height: 6.5vh;
	width: 6.5vh;
	top: 60%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
}
.MainScreen .categories .category .controls .links .paid {
	display: inline-block;
	vertical-align: top;
	background: #3f5661;
	width:50%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	cursor: pointer;
}
.MainScreen .categories .category .controls .links .paid:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALwSURBVHgB7dwLkqMgFAXQZ29s6JXhrAxnZXfaCkloAsgDDTG5p8rqroSfD8UfRoSIiIiIiIiIiIiIiIiIiIiIiIh2NQndALD+37mQbPGLTNP0V6jPGnT0cUHHUa0dAp/CjtiyEXjnvzcb+a1Pm2OEHmWC5nq2XOQ71AldrFtkJvBGdpLpCHZCJviHjdV43Ms+txMywTdysMTe8HmdMCr4hfo/qxMSQ4GRJ/vYTkgMAUYGeaW2PEViq+s9xbwuRhpFe+N77wW9K4vtK2Sn7dQ9N4qXllhRo8hroddc/vXzqZRBzsf4ZbX83K38rsmEy55iEl8tflnNmezfP/UsUlcPqvKhfJ/jLIzUBcUl8tpCettRV5jX5RIZvIGGgKyqjhm4xMhFeU1lvl9tnBKJwsYvct8FX52R+zAybz0s8QELA149ZAVlhENXVf4ovvNDgXEPnUXU9s3jF2qGg+0y1Af9qF7bVdgr0W44e62rtiOz6aMGne5iQdMBSIzFHfWqykLqdBSZc9Qz6QhC9+m2NnZh+i9cdr85+H4WagblcPb1s4RbwMKpFs+1doAR2k3tVfENGs9+cJ8ZUJX+aMpjwMsdhJ2ykDC9U6TVMKKgaX+cHmc6DYXi6T867ymJLhAuyPoWF2K2osJs5djnnlJ1YKA8tcQOjwvR8OwhqtNKYSVWptRgFIau1pVrDQwaxnV0zF7ATjfjtgp2qYxhQ1Ofo/OeUmtgtAFJtRUbJxYYcDvalSrE9lBjpAH6n0n0bM23MnB/Jty9flE+W0pYPdb79BadQci0w6GPUdRloddcfk0Gp6kQB03JQ18nnPuhfJTJKCsxMkDzyqbLsuEijdB6TPQrU115kN7IQODErPHAqYljgZNzxxvVCei4Vng7GP+CxucG/wrjXlFi8K+Qnkx17Qi+pPcseM5rqocNcW+hsDf0YuC1sM8b882B5491eLgMO3/k9xzTnNn//ad+CE9ERERERERERERERERERERERETH+g/i46O6TBYUgQAAAABJRU5ErkJggg==) no-repeat;
	background-size: contain;
	height: 9vh;
	width: 9vh;
	top:-5%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}
.MainScreen .categories .category .controls .links .paid.lower:after {
	top: 5%;
	height: 12vh;
	width: 12vh;
}
.MainScreen .categories .category .controls .links .paid span {
	font-size: 2.8vh;
	position: absolute;
	top: 7vh;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	white-space: nowrap;
	color:#fff;
}
.MainScreen .categories .category .controls .steps {
	width: 100%;
	text-align: center;
}
.MainScreen .categories .category .controls .steps {
	width: 100%;
	text-align: center;
	padding:1vh 0 1.5vh;
	border-bottom:1px solid #3f5661;
}
.MainScreen .categories .category .controls .steps i {
	display: inline-block;
	width: 1.3vh;
	height: 1.3vh;
	margin: 0.7vh;
	border-radius: 50%;
	border: 1px solid #3f5661;
	cursor: pointer;
}
.MainScreen .categories .category .controls .steps i:hover, .MainScreen .categories .category .controls .steps i.active {
	background: #3f5661;
}

.MainScreen .popup {
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	background: #f8104d;
}
.MainScreen .popup .close {
	display: block;
	position: absolute;
	width: 24px;
	height: 24px;
	top:3vh;
	right:3vh;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEQSURBVHgB7dVbDsIwDERRi5Vl/5syEpKF1JYmTfyYiZhPfnwPKkVUtel3Tch26tfzmpDsgPnsCkSBusIYqLGhus1MqOFWBtTjRmTUdBsiarkJCeXWgoByb6hEhd2uQIXfzESl3co4pNlPQ+RBrfq9Rhwuw0QElGM8Q2AwHkFwmJUwWIztSSA8xjYSSoOx3QXTYWw34XwY2yCqCdM6qCZBe8l//W31yOlOLwXd6bWtO/2xPgmFR80EwqJWwuBQHkEwKM+QclREQBkq8nA6KuNgGirz2wu/pQXPd9hN1bo3kPvtSox7AwLGrQUJs9yEiJluQ8bYhhsZMLZuKxPGdtvMhrH9QgkjxnaFOn7YhGzH/jdTPOtEe3985wAAAABJRU5ErkJggg==);
	background-size: cover;
	cursor: pointer;
}
.MainScreen .popup .desc {
	display: block;
	margin: 28vh auto 0;
	font-size: 1.6em;
	text-align: center;
	max-width: 45vh;
	color: #fff;
}
.MainScreen .popup .controls {
	max-width: 45vh;
	text-align: center;
	margin: 0 auto;
}
.MainScreen .popup .controls .buttonPopup {
	display: inline-block;
	color:#fff;
	font-size: 1.2em;
	margin: 15vh 0 0 6vh;
	padding: 1.6vh 6.6vh;
	border:2px solid #fff;
	cursor: pointer;
}
.MainScreen .popup .controls .buttonPopup:first-child {
	margin: 15vh 0 0;
}
.MainScreen .popup .keyArea {
	width: 100%;
	box-sizing: border-box;
	background: #cc0d3f;
	border-top:1px solid #fff;
	border-bottom: 1px solid #fff;
	padding: 5vh 2vh;
	text-align: center;
	margin-top: 5vh;
	font-size: 1.8em;
	color:#fff;
}
.MainScreen .popup .copyToClipboard {
	display: block;
	text-align: right;
	margin:5vh 10vh;
	font-size:1.1em;
	color:#fff;
	text-transform: uppercase;
	position: relative;
}
.MainScreen .popup .copyToClipboard:after {
	content: "";
	position: absolute;
	top:-0.5vh;
	right:-6vh;
	width: 4vh;
	height: 4vh;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEiSURBVHgB7dtrDoIwEATgkXiwcjLxZNST1a0PQoiPbP0hO50vaZQEko5lU0grYEopydpcYpnR4GAXJvtsungPDsZz/mDthMBswFz9P1pLq+P8aHs3odWmLkKM9i99HtCZ7gIfEdO0+n6BQ8jANhOd0Ug1zK7pll5NBRP2L9e2lIF3TivxnrkXtf+uW7rcf5CEoKz/c281nLw1PK0PvG8q//C8lZ80LbFTYHYKzE6B2SkwOwVmp8DsFJidArNTYHYKzE6B2SkwOwVm111gfFlDPn06FwFs+1xHOKMjgy3xjujIbUG8LmyX1/s7XJu+InCt4G/rVjsAAlBgdgrMrrvA3mmp/t0nITDvCGfElt0PDlFeGt4Y3TX8eLrKiCVbG63r+Qq+jhbuGwbyqgAAAABJRU5ErkJggg==);
	background-size: cover;
}
.MainScreen .popup .copied {
	position: absolute;
	bottom: 2vh;
	display: block;
	text-align: center;
	border: 1px solid #fff;
	padding: 2vh 4vh;
	left:50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	color:#fff;
	word-wrap: break-word;
	white-space: nowrap;
}
.MainScreen .topBar .navigation {
	position: absolute;
	right: 30px;
	top: 6.2vh;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAA2CAYAAAD+ighrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABaSURBVHgB7dPBCsAgDAPQbl/WP9c/y1YYW9FDc5oH80DxUAoGYvYA4Pdp+MTbLRsGRi1vqni17d16xG2E00gx2Im5zn+GjidlWQcue4FaOFML5VdQC2crW3gBWSGsgpU01TcAAAAASUVORK5CYII=);
	height:5vh;
	width: 1vh;
	background-size: contain;
	cursor: pointer;
}
.MainScreen .infoPopup {
	position: absolute;
	top:0;
	left:0;
	width: 100%;
	min-height: 100vh;
	background: #000;
	color:#fff;
	overflow: hidden;
}
.MainScreen .titleCat {
	display: block;
	padding: 3.5vh 0 2vh 0;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
}
.MainScreen .infoPopup .close {
	display: block;
	position: absolute;
	width: 24px;
	height: 24px;
	top:3vh;
	right:3vh;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEQSURBVHgB7dVbDsIwDERRi5Vl/5syEpKF1JYmTfyYiZhPfnwPKkVUtel3Tch26tfzmpDsgPnsCkSBusIYqLGhus1MqOFWBtTjRmTUdBsiarkJCeXWgoByb6hEhd2uQIXfzESl3co4pNlPQ+RBrfq9Rhwuw0QElGM8Q2AwHkFwmJUwWIztSSA8xjYSSoOx3QXTYWw34XwY2yCqCdM6qCZBe8l//W31yOlOLwXd6bWtO/2xPgmFR80EwqJWwuBQHkEwKM+QclREQBkq8nA6KuNgGirz2wu/pQXPd9hN1bo3kPvtSox7AwLGrQUJs9yEiJluQ8bYhhsZMLZuKxPGdtvMhrH9QgkjxnaFOn7YhGzH/jdTPOtEe3985wAAAABJRU5ErkJggg==);
	background-size: cover;
	cursor: pointer;
	z-index: 1;
}
.MainScreen .infoPopup img {
	display: block;
	max-height: 50vh;
	max-width: 100%;
	position: relative;
	left:50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}
.MainScreen .infoPopup .content {
	margin-top: -4vh;
	padding: 0 4vh 4vh;
	text-align: justify;
	position: relative;
	background: #000;
}
.MainScreen .infoPopup .content:after {
	content: "";
	position: absolute;
	top: -10vh;
	left: 0;
	width: 100vw;
	height: 10vh;
	background: -webkit-linear-gradient(top, rgba(0,0,0,0.4), rgba(0,0,0,1));
	background: linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,1));
	-webkit-clip-path: polygon(0 35%, 100% 0%, 100% 100%, 0 100%);
	        clip-path: polygon(0 35%, 100% 0%, 100% 100%, 0 100%);
}
.MainScreen .infoPopup .controls {
	position: relative;
	font-size:0;
}
.MainScreen .infoPopup .controls .link-path {
	-webkit-clip-path: polygon(0 35%, 100% 0%, 100% 100%, 0 100%);
	        clip-path: polygon(0 35%, 100% 0%, 100% 100%, 0 100%);
}
.MainScreen .infoPopup .controls .links, .MainScreen .infoPopup .controls .links-2x {
	background: #424242;
	padding: 4px 0 0;
	-webkit-clip-path: polygon(0 32%, 100% 0%, 100% 100%, 0 100%);
	        clip-path: polygon(0 32%, 100% 0%, 100% 100%, 0 100%);
}
.MainScreen .infoPopup .download {
	display: inline-block;
	vertical-align: top;
	background: #000;
	width:25%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	border-right:2px solid #424242;
	cursor: pointer;
}
.MainScreen .infoPopup .download:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM3SURBVHgB7ZwLVvMgEIUnHvclrixxZeDK7h/+Eq04PINAIN85Oa1NyuN2uJQhdqFGAFjNU2EODmWOz2VZFI3OLorYD4l8pC6DRsMIUxI5hFA4HzFBoeiqoHzU+BB0JfYGrxGdkvg26lBZMqI8QVcgojMrZYKw8IJ6JtABWaoD8H8IgnoEfs/JjprM+gT1RovGekTqa3bzhLygP8YjUvGozaKHT9HVBuoBR/RUD3FHO9pHERoNrdi2UEvAT+vNDNLRnnZR1FP0eNrUbkbrqjHfbZJ2o+gEL5SJI3QVtUfZL1SLajym0xVuBHUAavsQIvM61AlVBUJCwos6gWlaeW9EesKrm/UPGBugTF4dFYj9IabDm3nsftdB96lIGyMiR6LzxBTCibU8TwqII3sX5gDxpNkC3IZ8qd0DpO+kiJhC1xHE0SBvR4Xt5/JUKOf0aje2d7o4+Ok5m+dSvr+u6KFBgd/EV+4NcRcOBmJSxeg5XVkBhDKi6C3J1AD40rUOgQRNhHcUOU9MhitQFk6QfapbaEIYLbbsjOIkiHuIPcENszuCAtwCBdACbfaLs03zPlwR9EaVsca/oJ5obdTcFzVqACODPCJIMRfXXG68Na7fZSvqEOiDObnRXHC28vlfIJPtV/ZZXPlG7QRM9GzWyzp5pp5NmosiMYlIcfcZoFHSHg1TLgjkg35M8yYfq5hyjr15QQNhhBDMqa/R9OI7aSH2Q44g1BGxxIuzPe/A/tp61if39+pIcg0rQY+I0s8Vpd0TVGSL+sTwE+T+5z2NNuYPSmiIRHlWpp418rqc/a5Y2IDwLlaNJ21Ulo3y+Svjfnft/wVX8zrkTIZxo/FQ9BBHuS6ITndYQinK58xObbw/+NnIRE3IE18pEWNiPxqKSNNMMkD+/ccEkpVtyKk/WaBSFZ+oS1HFu2nvjGKAW6AAt0ABinhQDM9GnutZJcpIpYpAsBaFeNxxmjTdlygjh1pDTNh/IyGFAn7VLagCLT0oSiS4UxKKKlBLINdQ8IrkEUdT7btXFQquxCVG3dgsINL4+fETIk2xw/IF0pJxc4lzECnSnOIcBESaW5wDdPLrDV1jidTNfdr/AKtY+LLSMyX7AAAAAElFTkSuQmCC) no-repeat;
	background-size: contain;
	height: 6vh;
	width: 6vh;
	top:65%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
}
.MainScreen .infoPopup .googlePlay {
	display: inline-block;
	vertical-align: top;
	background: #000;
	width:25%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	border-right:2px solid #424242;
	cursor: pointer;
}
.MainScreen .infoPopup .googlePlay:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMlSURBVHgB7ZwBctwgDEXZnsw9GUdTbvYbN0rH3VrwAYHB7p/JZGaRBHpry4DtDSEhANvnX8S/kv3z8GQpBEbPAqVHTalk9wtPENoktwaF9Gm1t0Vwp56EO4pIPB5spcR+eeH8apU8OvBVr4TwWR8U7Mt5EpL6MqD29i2sqjNA+rkwkKwYtwFlAdI2YSFZsZYHlQKk7VICyYp5ojXqUw6Q2ogFyToacJdCzgBSO0kkuSXis6C2MKNYQJYtmyC4pYxgNlAlgNR+q4Vk9Tc1qFJA6tMEyer3RNfXpxpA6ucBaf5CXgtIfTePpKYG1QJI/VOQilb3mHHp0gpIY6QSK94CwUyF3APQIZZ4QbLG5hW7aRChQd6QMjGP6lOfvAFpTOkA6ZpC3gOQxhULEhpqB0YX8l6ANLYkkthChVB290XQCqonII3fPKEkY/UB1RuQ9uE16z5N3srjROX1aQQg7acaUg7Om53AE9QoQIcEiiCxcE58BHltzKDjKECHwVuKpC01XQC5B5ULEkcCIgYuGZseSxcpcg6DhPRcqSwRrr8IW5F2CgMF/nEblzUXEvXJcoiUYUcRkNwXpEaff46iH2EivV6vn5//PjLtPfo0NRUgfF1ut0S7YPAm/jSANPHcKfTbZiSkKQAl4HwYLsMgXQ4oBUfrg1UjXCDRhR9zTRSFtEMtJMx+mWfhEPbFkDD7RLEUDuFnJ/a3f0Ra1y81auEQ/mYMrLJYbYXzFktysbDSdgcc4RxiihUTK22Y9YBziC0o1zxbrj3hHPoQcNrttlCjHoAGwYldwaQ6Cg3qDQcr3zhE35rjf8eC7Dh6AML1BdkXzKHz2AqoFxzc4fEX9JnnRAYMZn+AyhsORhdgclCxBhB8lw/XFGBycLEUkBecqcEcBhlLADnCiVODSQ00YdsMBzMVYEYsoFY4uPPLLC1w4Lk3c4VygGrhYIUCzAjpd1Zr4cQ8l8VfyayBg9UKMCMjqdJbMxF3A/MtMrlTOJhxaeAt9tt/87lHAWZVCEceA+ZbmaQjnv7zOLvQJsHKdYYR6t6DkNuDeRf4e033qjMlwv+fCQy/AESqLLhj5wz+AAAAAElFTkSuQmCC) no-repeat;
	background-size: contain;
	height: 6.5vh;
	width: 6.5vh;
	top:60%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
}
.MainScreen .infoPopup .controls .links-2x .download, .MainScreen .infoPopup .controls .links-2x .googlePlay {
	width: 50%;
}
.MainScreen .infoPopup .paid {
	display: inline-block;
	vertical-align: top;
	background: #000;
	width:50%;
	box-sizing: border-box;
	position: relative;
	height:12vh;
	cursor: pointer;
}
.MainScreen .infoPopup .paid:after {
	content: " ";
	position: absolute;
	display: block;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALwSURBVHgB7dwLkqMgFAXQZ29s6JXhrAxnZXfaCkloAsgDDTG5p8rqroSfD8UfRoSIiIiIiIiIiIiIiIiIiIiIiIh2NQndALD+37mQbPGLTNP0V6jPGnT0cUHHUa0dAp/CjtiyEXjnvzcb+a1Pm2OEHmWC5nq2XOQ71AldrFtkJvBGdpLpCHZCJviHjdV43Ms+txMywTdysMTe8HmdMCr4hfo/qxMSQ4GRJ/vYTkgMAUYGeaW2PEViq+s9xbwuRhpFe+N77wW9K4vtK2Sn7dQ9N4qXllhRo8hroddc/vXzqZRBzsf4ZbX83K38rsmEy55iEl8tflnNmezfP/UsUlcPqvKhfJ/jLIzUBcUl8tpCettRV5jX5RIZvIGGgKyqjhm4xMhFeU1lvl9tnBKJwsYvct8FX52R+zAybz0s8QELA149ZAVlhENXVf4ovvNDgXEPnUXU9s3jF2qGg+0y1Af9qF7bVdgr0W44e62rtiOz6aMGne5iQdMBSIzFHfWqykLqdBSZc9Qz6QhC9+m2NnZh+i9cdr85+H4WagblcPb1s4RbwMKpFs+1doAR2k3tVfENGs9+cJ8ZUJX+aMpjwMsdhJ2ykDC9U6TVMKKgaX+cHmc6DYXi6T867ymJLhAuyPoWF2K2osJs5djnnlJ1YKA8tcQOjwvR8OwhqtNKYSVWptRgFIau1pVrDQwaxnV0zF7ATjfjtgp2qYxhQ1Ofo/OeUmtgtAFJtRUbJxYYcDvalSrE9lBjpAH6n0n0bM23MnB/Jty9flE+W0pYPdb79BadQci0w6GPUdRloddcfk0Gp6kQB03JQ18nnPuhfJTJKCsxMkDzyqbLsuEijdB6TPQrU115kN7IQODErPHAqYljgZNzxxvVCei4Vng7GP+CxucG/wrjXlFi8K+Qnkx17Qi+pPcseM5rqocNcW+hsDf0YuC1sM8b882B5491eLgMO3/k9xzTnNn//ad+CE9ERERERERERERERERERERERETH+g/i46O6TBYUgQAAAABJRU5ErkJggg==) no-repeat;
	background-size: contain;
	height: 9vh;
	width: 9vh;
	top:-5%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}
.MainScreen .infoPopup .paid.lower:after {
	top: 5%;
	height: 12vh;
	width: 12vh;
}
.MainScreen .infoPopup .paid span {
	font-size: 2.8vh;
	position: absolute;
	top: 7vh;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	white-space: nowrap;
	color:#fff;
}

.LeftMenu {
	min-height: 100vh;
	background: #2d414b;
	box-sizing: border-box;
	padding: 3.3vh 0 0;
	position: relative;
	overflow: hidden;
}
.LeftMenu .title {
	margin:0 auto;
	font-size: 1em;
	font-weight: 300;
	text-align: center;
	text-transform: uppercase;
	color:#fff;
}
.LeftMenu .close {
	display: block;
	position: absolute;
	width: 24px;
	height: 24px;
	top:3vh;
	right:3vh;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEQSURBVHgB7dVbDsIwDERRi5Vl/5syEpKF1JYmTfyYiZhPfnwPKkVUtel3Tch26tfzmpDsgPnsCkSBusIYqLGhus1MqOFWBtTjRmTUdBsiarkJCeXWgoByb6hEhd2uQIXfzESl3co4pNlPQ+RBrfq9Rhwuw0QElGM8Q2AwHkFwmJUwWIztSSA8xjYSSoOx3QXTYWw34XwY2yCqCdM6qCZBe8l//W31yOlOLwXd6bWtO/2xPgmFR80EwqJWwuBQHkEwKM+QclREQBkq8nA6KuNgGirz2wu/pQXPd9hN1bo3kPvtSox7AwLGrQUJs9yEiJluQ8bYhhsZMLZuKxPGdtvMhrH9QgkjxnaFOn7YhGzH/jdTPOtEe3985wAAAABJRU5ErkJggg==);
	background-size: cover;
	cursor: pointer;
}
.LeftMenu .menu {
	list-style: none;
	margin: 12vh 0 0;
	padding: 0;
}
.LeftMenu .menu li {
	display: block;
	list-style: none;
	margin: 4vh 2vh 4vh 9vh;
	color:#fff;
	position: relative;
	font-size: 0.8em;
}
.LeftMenu .menu li.language:after {
	content: " ";
	position: absolute;
	left: -6vh;
	top: -0.9vh;
	width: 4vh;
	height: 4vh;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA9CAYAAAD1VdrqAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALSSURBVHgB5VsJsqQgDI3W3KszJ5M5mczJMm0X9vAxgYC4IK+qf6lkewT5bA5wMIhocpfofhKs+8EwDH+gNbyJ4kKW6mBa7MGdQfXIipUAd0Im4UUWg2e5LeTaCtgTrF9Qy+YZhJcMzUwgsxA4MjZY4p79DcmIT4SjIQT1dc4Eh4IdkbjgZ/aez0wMCEeB+GxOkXKM2IoSdzKY4etHeTUwtTz7xGJBCvaSxJ3cJFUm8dmfoRY0xgOZpHMtcY3tQ8gzNT4LcmxWInZziGPKPkO+vNmT0MEwclNKhtFRE3fyc4oUKTvWlCMVaSeb7ayAOGp0csgPkgGITyhagX1PeH5zBWP4wDUlhGcApVdjk3Ft82sJ76wPUYGcjkr73gm6u/Uo0Z+QokMsNTypDUf8ZOqpfaYSM/pGvedLp2AhDoRrgbFCF79d78WKysl2KA+ZOEs3lfWi9/Ws4PfqSgldm/rLkzWQNoberYVzYeF/HJq+xXjXX54jU/gX0nhxgZwEC3nw+Zj1YjOAUXRqTSHks7bWMWguBp4J411/WusIZTDetebVqIZgs8FAIRbiCDvQ4KuBy5/B/7eQHNM6lOhcrR/qlDb15jGUDCJaR9cZ75b4L/+ml85tQddN3a43tGc9+sbgJlU/iFcweldsJlU1hqwvOBG15hZjrbHvzWG868/cgltXR3gQQj7r3IJbiNA0XX9GhnAuEPIgry71uOamF9gZwBW6qoRS5nYv5exUbHWLiNeM8du5Bb07KrJu4VrYWKGLH9f76DFR6mjvrNvdUm7kZuBZMNzDbk9EsKAOzsConVEPp548Y1OKPCnOoTE6auIa+3TQIb+kUXraycaYcTrnLKuYbTr6LKvnaKIt7nR6uT7pSDAfh1T5vHooQ1eeV/cCkpzPxFcMMjZE4oL9KeYTzgT19k1KiNxgqfWvkEJkBl+Ce6/5UyNfGmbvW+WCbvpt6T+3M5MjpSuR2wAAAABJRU5ErkJggg==) no-repeat top right;
	background-size: cover;
}
.LeftMenu .menu li.language .right {
	position: absolute;
	right: 0;
}
.LeftMenu .menu li.feedback:after {
	content: " ";
	position: absolute;
	left: -6vh;
	top: -0.8vh;
	width: 4.4vh;
	height: 4vh;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAAA8CAYAAADSfGxZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJmSURBVHgB7ZsBroMgDIZx2b0eO5nsZLKT9dGsbp2jiChTkC/ZVKxG/rS1oHaKAADtFn/uZ1RdGPd7dF1nQ0Yd/pEIg6qbW0iMThDBqDowk21ZDCdED2+q8wrsE+tfL9mhR8Brw6EqJKaPF7ZuJgf3FDa8TftU9dkeDDNr4XMbHi6ptkci5hovQvtDaLdKJrTv+MQkktJZ4xGnowlBdEdOcjmIuX2emitbt6r0zC+j6SfyIYTzmruqEIp+HbJpoUGcTgiqKXrfjqoLKhofDeCn54bVCkEizKHRttrQgO8JJ6ueEzNYRxjWPowHVOcRHk8YPDY973t1HuHzBOcEt7njqhIC/POvGmImjWoJjYjEqCVbbLuqROD9HGQPPp5TSOFAuUFT2+C2xxDhtmY8yWKPgLjbUm60cC3D5FqHwDletkXnCIhIjLRtPYd/2CaFBrolPN1st9CgZezdAQeTOmjL3KSYZAkRdcJi29KEWCkChE5cjBBrRahCiC1ECAlRUh1h2LqYGME/2LJq5rFfkhCw7/sUi0SgwmrW20urIxaLoCI5eh2h1fv+n00EJDlHUK1vVUaAzT7nFAE5emiY0M6tREBKyRFm2rClCMhhhYDAZArdBTYTAUnOET+AJ+Jxlkl77FaLgCR5BGw7HxFT0WqVUQQk1SN+UVFqod3QcvZt2iWk1hF32O61Cul9LZxDsKPNlp32saaOyPrk/Bd1Cqc9DSeaEEQTgmhCEE0IoglBNCGIJgTRhCCaEET7lIlAjzDsgL2m6LMx6ZOR7HDQxUd/mtQTDygMM9mWRrrtA9iRV7zAyT+J/geEmAu8T2sA9QAAAABJRU5ErkJggg==) no-repeat top right;
	background-size: contain;
}
.LeftMenu .menu li.feedback .hidden {
	margin:4vh 0 0 -7vh;
	text-align: right;
}
.LeftMenu .menu li.feedback .hidden input, .LeftMenu .menu li.feedback .hidden textarea {
	display: block;
	width: 100%;
	margin: 3vh 0;
	padding: 1vh 2vh;
	border: none;
	box-sizing: border-box;
	background: none;
	color:#fff;
	outline: none;
}
.LeftMenu .menu li.feedback .hidden input {
	border-bottom:1px solid #fff;
}
.LeftMenu .menu li.feedback .hidden input::-webkit-input-placeholder, .LeftMenu .menu li.feedback .hidden textarea::-webkit-input-placeholder {
	color:#fff;
	opacity: 0.8;
}
.LeftMenu .menu li.feedback .hidden input::-ms-input-placeholder, .LeftMenu .menu li.feedback .hidden textarea::-ms-input-placeholder {
	color:#fff;
	opacity: 0.8;
}
.LeftMenu .menu li.feedback .hidden input::placeholder, .LeftMenu .menu li.feedback .hidden textarea::placeholder {
	color:#fff;
	opacity: 0.8;
}
.LeftMenu .menu li.feedback .hidden .submit {
	display: inline-block;
	margin:3vh 0 0;
	width: 35%;
	max-width: 20vh;
	text-transform: uppercase;
	border:2px solid #fff;
	font-weight: bold;
}
.LeftMenu .menu li.feedback .hidden textarea {
	border: 1px solid #fff;
	margin: 5vh 0 0;
}
.LeftMenu .menu li.logout:after {
	content: " ";
	position: absolute;
	left: -7vh;
	top: -0.6vh;
	width: 5vh;
	height: 4vh;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE8AAAA8CAYAAAAngufpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHySURBVHgB7ZwNjoMgEEbHzd6rejK7J9OejJVk3EUE+WtgqN9L2sYq7fQBVRAYSABKqXl7Gfkhiad+Gobhh6Shpal+GO34B2rEFsxC8kpaiHUrhdO+0URep+J2DgKrosU5qoR+byZhKP/fyki10V/qEkeC4ZgXO2iqjSMI0eJ2PJlet6aIKP6ZOKpwPXmO3Oui1JnYOf9F9XhY2yt1Tk15HwfkFQB5BUBeAd+UCV9m6JPAa2uurHRDsuSxuMXYnu4oMLna2uKYB4FrPM2U6JZCThpJ2D86JWGROOMz5h7FabLkvUPcJ5AsD+L+SZIHcUei5UHcmSh5EOcmKA/i/FzKg7hrvPIgLoxTHsTFcZJ3IU4qi2qUqXYgumOgtxsxIwnpiEBnaAFaXptxF/ms2+NFUlA4YURhyzF3QGAArzzeCYEXXMrjAyDQQ1AeHwSBDqLk8YEQaBEtjw+GQIMkeZwAAplkeZwIAgm3HovIlseJcdPbIKljgMejuNrCwV4OdR7D2/0QjeReFY9AOQ31imSNktICt5KkBWKIWQ4sbKUbg87QAiCvAMgrAPJ6wHWBTZ3RNP7clokEVMuJexzAYgWAKaOFQWCyckIwi0ug6nCaPBZoSOdvgQYsDZLGYWWLZtd5HMST+mGylwRpVvJMVKfLIf0C8JE5c6JYllcAAAAASUVORK5CYII=) no-repeat top right;
	background-size: contain;
}

.loader {
	position: fixed;
	top:0;
	left:0;
	background: rgba(255,255,255,0.3);
	width: 100vw;
	height: 100vh;
	z-index: 10;
}


